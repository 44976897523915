<template>
    <div class="container-fluid">
        <div class="row my-3"
            v-for="data_description in this.component.component_data_description"
            v-bind:key="data_description.id">
            <div class="col-small mt-4">
                <button :disabled="!$store.state.me.is_superuser || data_description.has_data" type="button" class="btn btn-danger me-2" @click="removeItem(data_description)"><i class="fa-solid fa-minus"></i></button>
            </div>
            <div class="col-2">
                    
                <label for="inputDescCol" class="form-label" >{{ $t("desc_col") }}</label>
                <input :disabled="!$store.state.me.is_superuser" id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_description" >
            </div>
            
            <div class="col-md-oneandahalf">
                <label for="inputUnitCol" class="form-label" >{{ $t("unit_col") }}</label>   
                <template v-if="$store.state.me.is_superuser">
                    <select :disabled="!$store.state.me.is_superuser" class="form-select " aria-label="Default select example" v-model="data_description.col_unit">
                        <option  v-for="unit in this.$store.state.componentUnits" 
                        v-bind:key="unit.name" v-bind:value="unit.name">[{{unit.unit}}] {{unit.name}}</option>
                    </select>
                </template>
                <template v-else>  
                    <input disabled id="inputCol" type="text" name="inputUnitCol" class="form-control"  v-model="data_description.col_unit" >
                </template>
            </div>
            <div class="col-1">
                <label for="inputCol" class="form-label" >{{ $t("col_limit_low") }}</label>
                <input :disabled="!$store.state.me.is_superuser" id="inputCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_limit_low" >
            </div>
            <div class="col-1">
                <label for="inputCol" class="form-label" >{{ $t("col_limit_upper") }}</label>
                <input :disabled="!$store.state.me.is_superuser" id="inputCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_limit_upper" >
            </div>
            <div class="col-1">
                <label for="inputCol" class="form-label" >{{ $t("col") }}</label>
                <input :disabled="!$store.state.me.is_superuser" id="inputCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_component_data" >
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button :disabled="!$store.state.me.is_superuser" class="btn btn-success mb-0 ms-auto" @click="addComponentDescription">{{ $t("addComponentDescription") }}</button>
            </div>
            <div class="col-6 text-end">
                <button :disabled="!this.canWritePlant" class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
            </div>
        </div>

    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditDescriptionComponent',
    emits: [
        'copyDescription'
    ],
    props: {
        componentId: {
            type: Number,
            required: true,
        },
        canWritePlant: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
    },
    data() {
        return {    
            componentIdOld: 0,
            component: {}
        }
    },
    async mounted(){
        this.getUnitsFromBackend()
        await this.getComponentDataFromBackend()
    },
    updated(){
        console.log('entry updated')
        if(this.componentIdOld === 0){
            this.componentIdOld = this.componentId
            
        }
        if(this.componentIdOld != this.componentId){
            this.getComponentDataFromBackend()
            this.componentIdOld = this.componentId
        }
        console.log('exit updated')
    },
    methods: {
        copyDescription(){
            console.log('entry copyDescription')

            this.$emit('copyDescription')
            console.log('exit copyDescription')
        },
        getComponentDataFromBackend(){
            console.log('entry getComponentDataFromBackend')
            axios
                .get(`/api/v1/components/${this.componentId}/`, )
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.component = response.data
                    this.manipulateComponentDataDescription()
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getComponentDataFromBackend')
        },
        manipulateComponentDataDescription(){
            console.log('entry manipulateComponentDataDescription')
            for (let i = 0; i < this.component.component_data_description.length; i++) {
                this.component.component_data_description[i].nr_pos = i
                this.component.component_data_description[i].uuid = uuidv4()
                
                delete this.component.component_data_description[i].component
            }
            console.log('exit manipulateComponentDataDescription')
        },
        removeItem(itemToRemove){
            console.log('entry removeItem: ')
            console.log(itemToRemove)
            const index = this.component.component_data_description.findIndex((element, index) => {
                if(element.uuid === itemToRemove.uuid){
                    return true
                }
            })
            console.log('index: ' +index)
            this.component.component_data_description.splice(index, 1)
            console.log('exit removeItem')
        },  
        addComponentDescription(){
            console.log('entry addComponentDescription')
            this.component.component_data_description.push({
                nr_pos: this.component.component_data_description.length,
                col_description: '',
                col_unit: '',
                uuid: uuidv4(),
                created_by: this.$store.state.user.id,
                modified_by: this.$store.state.user.id,
            })

            console.log('exit addComponentDescription')
        },
        getUnitsFromBackend(){
            console.log('entry getUnitsFromBackend')

            axios
                .get(`/api/v1/componentunits/`)
                .then(response => {
                    this.$store.commit('setComponentUnits', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getUnitsFromBackend')
        },
        submitForm() {
            for (let i = 0; i < this.component.component_data_description.length; i++) {
                
                delete this.component.component_data_description[i].component
            }
            axios
                .patch(`/api/v1/components/${this.component.id}/`, this.component)
                .then(response => {
                    
                    createToast({text: this.$t("component altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
    
}
</script>