<template>
  <template v-if="$store.state.isAuthenticated"> 
      <Topbar/>
    </template>
  <metainfo>
  </metainfo>
  
  <div id="wrapper" class="h-100 d-flex flex-column ">
    
    <div class="container-fluid">
      <div class="row">
        <main class="">
          <router-view/>
          <div id="toast-container"></div>
        </main>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
  import axios from 'axios'
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Sidebar,
    Topbar,
    Footer
  },
    beforeCreate() {
      console.log("init beforeCreate")
      this.$store.commit('initializeStore')

      const token = this.$store.state.token
      console.log(window.location.pathname)
      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
        let actual_path = window.location.pathname
        if(actual_path === '/'){
          this.$router.push('/dashboard')
        }
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    async mounted(){
      
      if(this.$store.state.isAuthenticated){
        await this.getAuth()
        await this.getMe()
      }else{
          console.log("User ist nicht angemeldet")
      }
      
    },
    methods:{
      getPlants(){
        axios
          .get('/api/v1/dashboard/')
          .then(response => {
            console.log('plants loaded')
            console.log(response.data)
            this.$store.commit('setPlants', response.data)
                    
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })
      },
      getOrganisation(){
        console.log('entry getOrganisation')
        const organisationID = this.$store.state.me.organisation

        axios
          .get(`/api/v1/organisations/${organisationID}/`)
          .then(response => {
            this.$store.commit('setOrganisation', response.data)
            console.log('Organisation gesetzt')
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })

        console.log('exit getOrganisation')
      },
      getAuth(){
        axios
          .get('/api/v1/authgroup/')
          .then(response => {
            this.$store.commit('setPermissions', response.data[0])
            console.log('getAuth fertig geladen')
          })
          .catch(error => {

            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("token")
            localStorage.removeItem("mustSetupCompany")
            this.$store.commit('removeToken')
            localStorage.removeItem("permissions")
            this.$router.push('/')
          })

 
      },
      getMe(){
        axios
          .get("/api/v1/users/me/")
          .then(response => {
            this.$store.commit('setMe', response.data)
            this.getOrganisation()
            this.getPlants()
            console.log('getMe fertig geladen')
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })
      }
    }
}
</script>

<style lang="scss">
@import '/scss/custom.scss';
@import './assets/fontawesome/css/all.css';
</style>
