<template>
  <div class="row mt-2">
    <div class="col-6 col-md-10">
      <div class="text-start" v-if="this.allAvailableTypes.length > 1">
        {{$t("planttype_filter")}}
        <template v-for="typ in this.allAvailableTypes"
            v-bind:key="typ">
            <img class="me-2" :class="{'filter-selected': this.activeFilter.includes(typ)}" :src="require('@/assets/icons/'+ typ + '.png')" @click="setTypeFilter(typ)"/>
          </template>
      </div>
    </div>
    <div class="col-6 col-md-oneandahalf text-end">
      <div class="text-end">
        <select class="form-select " aria-label="Default select example" v-model="this.currentTabComponent">
          <option value="TileDashboard">{{$t("dashboard_tile")}}</option>
          <option value="ListDashboard">{{$t("dashboard_list")}}</option>
          <option v-if="$store.state.me.is_superuser" value="ListDashboardNew">{{$t("dashboard_list_new")}}</option>
          <option value="GoogleMaps">{{$t("dashboard_google_maps")}}</option>
        </select>
  
      </div>
    </div>
  </div>

  <template v-if="this.$store.getters.getPlants">
    <component :is="currentTabComponent" v-bind="currentProperties"></component>
  </template>


</template>

<script>

import axios from 'axios'
import GoogleMaps from '@/components/dashboard/GoogleMaps.vue'
import ListDashboard from '@/components/dashboard/ListDashboard.vue'
import ListDashboardNew from '@/components/dashboard/ListDashboardNew.vue'
import TileDashboard from '@/components/dashboard/TileDashboard.vue'
export default {
  name: 'Dashboard',
  components: {
        GoogleMaps,
        ListDashboard,
        ListDashboardNew,
        TileDashboard
  },
  data(){
    return {
      currentTabComponent: "TileDashboard",
      activeFilter: []
    }
  },
  mounted(){
    
  },
  beforeUnmount(){
        console.log('entry beforeUnmount')
    },
  methods: {
    setTypeFilter(typ){
      console.log('entry setTypeFilter')

      if(this.activeFilter.includes(typ)){
        let position = 1
        let pos_delete = this.activeFilter.map(e => e).indexOf(typ)
        console.log('pos_delete: ' + pos_delete)
        this.activeFilter.splice(pos_delete, 1)
        
      }else{
        this.activeFilter.push(typ)
      }

      console.log('exit setTypeFilter')
    }
  },
  computed: {
    allAvailableTypes(){
        return Array.from(new Set(this.$store.getters.getPlants.map(plant => plant.type))).filter(n => n)
    },
    currentProperties(){
      if(this.activeFilter.length == 0){
        return {plants: this.$store.getters.getPlants}
      }
      return {plants: this.$store.getters.getPlants.filter(plant => this.activeFilter.includes(plant.type))}
    },
  }
}
</script>
